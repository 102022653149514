<template>
  <div class="d-block w-full">
    <div class="flex w-full justify-between">
      <h3 class="text-right pb-2 " style="font-family: Segoe UI" >{{ data.title }}</h3>
    </div>
    <vs-divider></vs-divider>

    <div class="font-light rounded p-2 pb-0">

      <div class="text-center flex justify-between gap-8 items-center ">
        <span class="mb-4 block"> وضعیت:
          <span v-if="data.is_over === 0" class="text-h-success "> آنلاین</span>
          <span v-else-if="data.is_over === 1" class="text-h-danger "> منقضی</span>
        </span>
      </div>
      <!-- Machine Detail -->

      <div class="flex justify-center gap-2 items-center ">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer" @click="copyToClipboard(data.password), passblur = !passblur" :class="{blurtext : passblur}">
          {{ data.password }}
          <span class="text-xs text-white d-block text-center">کلمه عبور</span>
        </div>

        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer" @click="copyToClipboard(data.config)">
          Qv2ray
          <span class="text-xs text-white d-block text-center">کانفیگ</span>
        </div>

      </div>
      <!-- Security Information -->
      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-full text-center text-sm cursor-pointer" @click="copyToClipboard('wget -O setup.sh http://tools.haiocloud.com/haiogateway.sh && bash setup.sh')">
          wget -O setup.sh http://tools.haiocloud.com/haiogateway.sh && bash setup.sh
          <span class="text-xs text-white d-block text-center">نصب</span>
        </div>
      </div>

      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center text-xl cursor-pointer">
          {{ data.package.total_traffic }}
          <span class="text-xs text-white d-block text-center">مقدار ترافیک (GB)</span>
        </div>
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer">
          {{ data.total_traffic_usage }}
          <span class="text-xs text-white d-block text-center">ترافیک استفاده شده (GB)</span>
        </div>
      </div>

      <div class="mt-4 flex justify-center gap-2 items-center">
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer">
          {{ data.expired_at }}
          <span class="text-xs text-white d-block text-center">تاریخ پایان</span>
        </div>
        <div class="bg-h-primary rounded p-2 w-1/2 text-center cursor-pointer">
          {{ data.usage_percent }}
          <span class="text-xs text-white d-block text-center">درصد مصرف</span>
        </div>
      </div>

      <vs-divider />
      <!-- Machine Actions -->
      <div class="flex items-center mt-3 w-full justify-center">
        <div :id="'renew' + data.id" title="تمدید"
          class="hover:shadow-xl  m-2 p-2 hover:text-white cursor-pointer"
          @click="popupActiveRenew = true, id = data.id">
          <b-tooltip variant="info" :target="'reboot' + data.id" triggers="hover">
            تمدید
          </b-tooltip>
          <a class="cursor-pointer btn bg-h-secondary border-0 btn-secondary rounded text-sm md:text-base py-1 mb-2 text-white" href="javascript:void(0)">تمدید/رزرو</a>
        </div>
        <div :id="'help' + data.id"
          class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
          <b-tooltip variant="info" :target="'help' + data.id" triggers="hover">
            راهنما
          </b-tooltip>
          <a target="_blank" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary rounded text-sm md:text-base py-1 mb-2 text-white" href="https://haiocloud.com/%d8%a2%d9%85%d9%88%d8%b2%d8%b4-%d8%a7%d8%b3%d8%aa%d9%81%d8%a7%d8%af%d9%87-%d8%a7%d8%b2-%d8%aa%d8%ad%d8%b1%db%8c%d9%85-%d8%b4%da%a9%d9%86/">راهنمای کار با ضد تحریم</a>
        </div>
      </div>
    </div>
    <vs-divider />

    <!-- Reboot VM -->
    <vs-popup class="holamundo" title="تمدید / رزرو ضد تحریم" :active.sync="popupActiveRenew">
      <div class="flex justify-content-center items-center flex-col">
        <div>
          <img width="200" src="../../../../../assets/images/elements/cloud-outline.webp" alt="">
        </div>
        <div>
          آیا از تمدید/رزرو ضد تحریم به مدت یک ماه و مبلغ {{ data.package.price }} تومان مطمئن هستید ؟
        </div>
        <div class="flex justify-content-between mt-4">
          <vs-button color="primary" @click="setRenewPrompt(id)" class="rounded">بله </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
export default {
  components: {
    RoundSlider
  },
  props: ['data'],
  data () {
    return {
      popupActiveRenew: false,
      selectedID: 0,
      rotation: false,
      passblur: true
    }
  },
  computed: {
  },
  watch: {
    data () {
    }
  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    setRenewPrompt (d) {
      this.renew(d)
      this.popupActiveRenew = false
    },
    renew (id) {
      this.rebootPrompt = false
      this.$http
        .put(`/anti/sanction/${id}/renew`)
        .then((res) => {
          this.data.is_over = 0
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
          this.$emit('renew-success')
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    }

  }
}
</script>

<style scoped>
  .future-card {
    border-bottom: 2px solid #3d2353 !important;
    border-radius: 0;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
</style>
<style>
.rotate {
    -moz-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
}
.rotate.reload {
    -moz-transform:rotate(360deg);
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.blurtext {
  color: transparent;
  text-shadow: 0 0 8px #f1f1f1;
}
</style>
