<template>
  <div>
  <div class="row gap-5">
    <div class="block   w-full text-right mr-2">
      <router-link to="/anti/sanction" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست ضد تحریم ها</router-link>
      </div>
    <div class="bg-h-primary-light w-full  lg:w-1/4 p-5 rounded-lg border border-dashed border-white border-opacity-25 shadow-lg">
      <anti-sanction-info-card v-if="isInfoLoaded" :data="antiSanctionInfo" :key="antiSanctionInfo.id" @renew-success="getInfo"></anti-sanction-info-card>

    </div>

    <div class="w-full lg:w-2/3">
      <div class="float-right hidden lg:block">
      </div>
      <b-tabs content-class="mt-3" refs="tabs" >

        <b-tab title="بسته های رزرو" :active="$route.hash === '#reserve'" href="plan" @click="tabHash('reserve')">
          <anti-sanction-reserve-list v-if="isInfoLoaded" :data="antiSanctionInfo" :key="antiSanctionInfo.id"></anti-sanction-reserve-list>
        </b-tab>

      </b-tabs>
      </div>

  </div>

  </div>

</template>

<script>
import AntiSanctionInfoCard     from './AntiSanctionInfoCard.vue'
import AntiSanctionReserveList from './AntiSanctionReserveList.vue'

export default {
  components : {
    AntiSanctionInfoCard,
    AntiSanctionReserveList
  },
  data () {
    return {
      antiSanctionId : '',
      antiSanctionInfo : {},
      isInfoLoaded: false
    }
  },
  watch : {
    $route () {
      this.antiSanctionId = this.$route.params.id
      this.getInfo()
    }
  },
  computed: {

  },
  methods : {
    tabHash (val) {
      location.hash = val
    },
    getInfo () {
      this.$http.get(`/anti/sanction/${this.antiSanctionId}`).then((result) => {
        this.antiSanctionInfo = result.data.params
        this.isInfoLoaded = true
      }).catch(() => {

      })
    }
  },
  created () {
    this.antiSanctionId = this.$route.params.id
    this.getInfo()
  }
}
</script>
