<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">

      <table v-if="AntiSanctionReserves && AntiSanctionReserves.length > 0" class="w-full text-sm text-left  text-grey rounded-t">
        <thead class="text-xs text-grey ">
        <tr class="border  border-white border-opacity-25">
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">شناسه</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">عنوان</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">مقدار ترافیک (GB)</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">هزینه</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">تاریخ خرید</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">زمان فعال سازی</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">وضعیت</th>
        </tr>
        </thead>
        <tbody class="">
        <tr class="border border-white border-opacity-25" v-for="item in AntiSanctionReserves" :key="item.id">
          <th scope="row" class="px-6 py-4 font-medium text-grey whitespace-nowrap">{{ item.id }}</th>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.package.title }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.package.total_traffic }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.package.price }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.created_at }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ (item.process_date_at == '' ? 'پس از اتمام بسته فعلی' : item.process_date_at) }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4"> {{ item.status }}</td>
        </tr>
        </tbody>
      </table>
    <div v-if="AntiSanctionReserves.length === 0">
      <div class="alert alert-warning">
        بسته رزرو ندارید
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data', 'rotation'],
  data () {
    return {
      id: this.data.id,
      isLoading: false,
      status: '',
      AntiSanctionReservesParams: [],
      // AntiSanctionReserves: this.data.reserve
      AntiSanctionReserves: []
    }
  },
  methods: {

  },
  watch: {
    data: {
      handler (newVal) {
        this.AntiSanctionReserves = newVal.reserve
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.AntiSanctionReserves = this.data.reserve
  },
  mounted () {
    this.AntiSanctionReserves = this.data.reserve
  }
}
</script>

